import React from 'react';
import '../PricingTable/pricingTable.css'

const PricingTable: React.FC = () => {
  return (
    <div className="background">
      <div className="container">
      <h4 style={{ textAlign: "center" }}>Precios</h4>
        <div className="panel pricing-table">

          <div className="pricing-plan">
            <img src="https://www.corporaciontecnologica.com/export/sites/cta/.galleries/IMAGENES-Contenido-Menu-desplegable/IP.png" alt="" className="pricing-img" />
            <h2 className="pricing-header">Personal</h2>
            <ul className="pricing-features">
              <li className="pricing-features-item">Custom domains</li>
              <li className="pricing-features-item">Sleeps after 30 mins of inactivity</li>
            </ul>
            <span className="pricing-price">Free</span>
            <a href="#/" className="pricing-button">Sign up</a>
          </div>

          <div className="pricing-plan">
            <img src="https://www.corporaciontecnologica.com/export/sites/cta/.galleries/IMAGENES-Contenido-Menu-desplegable/IP.png" alt="" className="pricing-img" />
            <h2 className="pricing-header">Small team</h2>
            <ul className="pricing-features">
              <li className="pricing-features-item">Never sleeps</li>
              <li className="pricing-features-item">Multiple workers for more powerful apps</li>
            </ul>
            <span className="pricing-price">$150</span>
            <a href="#/" className="pricing-button is-featured">Free trial</a>
          </div>

          <div className="pricing-plan">
            <img src="https://www.corporaciontecnologica.com/export/sites/cta/.galleries/IMAGENES-Contenido-Menu-desplegable/IP.png" alt="" className="pricing-img" />
            <h2 className="pricing-header">Enterprise</h2>
            <ul className="pricing-features">
              <li className="pricing-features-item">Dedicated</li>
              <li className="pricing-features-item">Simple horizontal scalability</li>
            </ul>
            <span className="pricing-price">$400</span>
            <a href="#/" className="pricing-button">Free trial</a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PricingTable;
