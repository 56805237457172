import React, { useEffect, useRef } from "react";
import ContainerCards from "../microcomponents/Cards/Cards";
import "./sales-page.css";
import "./header.scss";
import Button from '../microcomponents/Button/button';
import Footer from '../microcomponents/Footer/footer';
import PricingTable from '../microcomponents/PricingTable/pricingTable';

function SalesPage() {
  const myNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    const navbar = document.getElementById("navbar");
    const logo = document.getElementById("logo");
    const mybutton = document.getElementById("up");

    if (navbar && logo && mybutton) {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        navbar.style.padding = "25px 10px";
        logo.style.fontSize = "25px";
        mybutton.style.display = "block";
      } else {
        navbar.style.padding = "40px 10px";
        logo.style.fontSize = "35px";
        mybutton.style.display = "none";
      }
    }
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function openNav() {
    if (myNavRef.current) {
      myNavRef.current.style.width = "100%";
    }
  }

  function closeNav() {
    if (myNavRef.current) {
      myNavRef.current.style.width = "0%";
    }
  }

  return (
    <div>
      <button className="btn_responsive w3-margin" onClick={openNav}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </button>
      <h3 className="btn_responsive w3-margin w3-center">AntiPhishing</h3>
      <div className="icon-bar">
        <a href="/" className="facebook">
          <i className="fa fa-facebook"></i>
        </a>
        <a href="/" className="instagram">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="/" className="youtube">
          <i className="fa fa-youtube"></i>
        </a>
      </div>
      <div className="j">
        <div id="navbar">
          <a href="/" id="logo">
            <img
              src="https://i.ibb.co/K5XbDmQ/Sin-t-tulo-400-80-px.png"
              alt="Logo Proyecto"
              style={{ height: "40px" }}
            />
          </a>
          <a className="default" href="/contact">
            Planes
          </a>
          <div id="navbar-right">
            <a className="active default" href="/login">
              Ingresar
            </a>
            <a className="default" href="/contact">
              Ayuda
            </a>
            <a className="default" href="/about">
              Apoyo
            </a>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "10%" }}>
        <main>
          <section className="slides">
            <section className="slide is-active">
              <div className="slide__content">
                <figure className="slide__figure">
                  <div
                    className="slide__img"
                    style={{
                      backgroundImage:
                        'url(https://source.unsplash.com/nfTA8pdaq9A/2000x1100)',
                    }}
                  ></div>
                </figure>
                <header className="slide__header ">
                  <h2 className="slide__title w3-round w3-padding">
                    <span className="title-line w3-margin w3-text-white">
                      <span>No hay mejor seguridad que la conciencia</span>
                    </span>
                    <span className="title-line w3-margin">
                      <Button className="btnWhite" text="Empezar" />
                    </span>
                  </h2>
                </header>
              </div>
            </section>
          </section>
        </main>
        
        <ContainerCards />
        
        <PricingTable />
       
        

       
      </div>

      <Footer /> {/* Aquí utilizamos el componente Footer */}
      <button
        onClick={scrollToTop}
        id="up"
        title="Go to top"
        style={{ display: "none" }}
      >
        <i className="fa fa-arrow-up" aria-hidden="true"></i>
      </button>

      {/* Overlay Navigation */}
      <div id="myNav" className="overlay btn_responsive" ref={myNavRef}>
        <button className="closebtn" onClick={closeNav}>
          &times;
        </button>
        <div className="overlay-content">
          <a href="/planes">Planes</a>
          <a href="/login">Ingresar</a>
          <a href="/ayuda">Ayuda</a>
          <a href="/apoyo">Apoyo</a>
        </div>
      </div>

   
    </div>
  );
}

export default SalesPage;
