import React, { useState, ChangeEvent, FormEvent } from "react";
import axios from "axios";
import "./sales-page.css";
import "./login-page.css";

interface User {
  username: string;
  email: string;
  password: string;
  roles: string[];
}

function SalesPage() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "username":
        setUsername(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSignUp = async (event: FormEvent) => {
    event.preventDefault();
    try {
      const newUser: User = {
        username,
        email,
        password,
        roles: ["admin"],
      };
      await axios.post("http://localhost:4000/api/auth/signup", newUser);
      // Procesar la respuesta o redireccionar a otra página después del registro exitoso
      console.log("Registro exitoso");
    } catch (error) {
      // Manejar el error de registro
      console.error("Error de registro", error);
    }
  };

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();
    try {
      const loginData = {
        email,
        password,
      };
      await axios.post("http://localhost:4000/api/auth/signin", loginData);
      // Procesar la respuesta o redireccionar a otra página después de iniciar sesión exitosamente
      console.log("Inicio de sesión exitoso");
    } catch (error) {
      // Manejar el error de inicio de sesión
      console.error("Error de inicio de sesión", error);
    }
  };

  return (
    <div>
      {/* Resto del código omitido para mayor claridad */}

      {/* Formulario de Registro */}
      <form className="registration-form" onSubmit={handleSignUp}>
        <h2>Registro</h2>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            name="username"
            value={username}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit">Registrarse</button>
      </form>

      {/* Formulario de Inicio de Sesión */}
      <form className="login-form" onSubmit={handleLogin}>
        <h2>Iniciar Sesión</h2>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit">Iniciar Sesión</button>
      </form>

      {/* Resto del código omitido para mayor claridad */}
    </div>
  );
}

export default SalesPage;
