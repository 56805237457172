import React from "react";
import '../Cards/cards.css'

function Card({ imgSrc, name, description }: { imgSrc: string; name: string; description: string }) {
  return (
    <div className="card">
      <div className="cover">
        <img src={imgSrc} alt={name} />
        <div className="img__back"></div>
      </div>
      <div className="description">
        <h2>{name}</h2>
        <p>{description}</p>
        {/* <button
                className="button"
                onClick={() => (window.location.href = "/signup")}
              >
                Sign Up
              </button> */}
      </div>
    </div>
  );
}
function ContainerCards() {
  return (
    <div className="container__cards">
      <Card
        imgSrc="https://i.ibb.co/RCfx8s3/Dise-o-sin-t-tulo-18.png"
        name="Protección proactiva"
        description="Te permitimos identificar y mitigar las vulnerabilidades de seguridad antes de que los atacantes puedan explotarlas.
         Al simular ataques de phishing, evaluamos la preparación de tus empleados y fortalecemos su conocimiento en seguridad."
      />
      <Card
        imgSrc="https://i.ibb.co/X4hLVd0/Dise-o-sin-t-tulo-20.png"
        name="Informes detallados"
        description="Informes detallados y análisis de las pruebas de phishing realizadas en tu empresa. 
        Identifica a los empleados que necesitan una mayor capacitación y sigue su progreso a medida que fortalecen sus habilidades en seguridad."
      />
      <Card
        imgSrc="https://i.ibb.co/30p7Fx8/Dise-o-sin-t-tulo-21.png"
        name="Capacitación"
        description="Ofrecemos cursos de capacitación personalizados para tus empleados, diseñados para aumentar su conciencia sobre el phishing y brindarles las habilidades necesarias para identificar y evitar ataques cibernéticos. 
        Nuestros expertos en seguridad están aquí para apoyarlos en cada paso del camino."
      />
    </div>
  );
}

export default ContainerCards;
