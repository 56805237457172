import React from 'react';
import SalesPage from './components/SalesPage';
import LoginPage from './components/LoginPage';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



function App() {
  return (
    <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<SalesPage />} />
        <Route path="/login" element={<LoginPage/>} /> 
      </Routes>
    </div>
  </Router>
  );
}

export default App;
